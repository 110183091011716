import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import * as styles from "../../styles/facilities.module.css"
import defaultImg from "../../images/facilities-thumb-default.jpg"

const FacilitiesPage = ({ data }) => {
    const facilityCategories = data?.allWpFacilityCategory?.nodes

    return (
        <Layout activeMenuId={4}>
            {data?.wpPage?.blocks.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <React.Fragment key={i}>
                                <Seo
                                    title={coverMain?.title}
                                    description={data?.wpPage?.seo?.metaDesc}
                                    date={data?.wpPage?.date}
                                    img={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaItemUrl
                                    }
                                    imgHeight={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.height
                                    }
                                    imgWidth={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.width
                                    }
                                />
                                <PageHeader
                                    hasUnderline
                                    title={coverMain?.title}
                                    subTitle={coverMain?.text}
                                    image={coverMain?.img?.mediaItemUrl}
                                    breadcrumbs={[
                                        { label: coverMain?.title, link: null },
                                    ]}
                                />
                            </React.Fragment>
                        )
                    case "core/shortcode":
                        return (
                            <div
                                key={i}
                                className={`mx-auto pt-4 pb-24 ${styles.wrap}`}
                            >
                                <div
                                    className={`px-4 grid grid-cols-2 gap-0 ${styles.row}`}
                                >
                                    {facilityCategories.map((cat, catI) => {
                                        const image =
                                            cat?.acfFacilityCategory?.icon
                                                ?.sourceUrl || defaultImg
                                        return (
                                            <Link
                                                key={catI}
                                                to={`/facilities/${cat?.slug}`}
                                                className={`mx-4 mb-6 flex-1 flex justify-center items-center ${styles.item}`}
                                            >
                                                <div className="hidden md:block">
                                                    <div
                                                        className={
                                                            styles.itemImage
                                                        }
                                                    >
                                                        <img
                                                            src={image}
                                                            alt={
                                                                cat
                                                                    ?.acfFacilityCategory
                                                                    ?.icon
                                                                    ?.altText
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="p-4 flex-1">
                                                    <h2 className="font-serif my-4 lg:my-0 text-xl lg:text-3xl">
                                                        {cat?.name}
                                                    </h2>
                                                    <p className="my-4 lg:my-0">
                                                        <strong>
                                                            {cat?.count === null
                                                                ? 0
                                                                : cat?.count}
                                                        </strong>{" "}
                                                        places
                                                    </p>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    {
        wpPage(slug: { eq: "facilities" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            mediaItemUrl
                        }
                        text
                        title
                    }
                }
                ... on WpAcfTextWColCardsBlock {
                    textWColCards {
                        cards {
                            title
                            link {
                                url
                            }
                            img {
                                altText
                                mediaItemUrl
                            }
                        }
                    }
                }
            }
        }
        allWpFacilityCategory(filter: { language: { code: { eq: EN } } }) {
            nodes {
                name
                slug
                count
                acfFacilityCategory {
                    icon {
                        altText
                        sourceUrl
                    }
                }
            }
        }
    }
`

export default FacilitiesPage
